<style lang="scss" scoped>
.el-input {
  width: 400px;
}
</style>
<template>
  <el-form
    size="small"
    :inline="true"
    :ref="(el) => (state.formInline = el)"
    label-width="100px"
    :model="state.form"
    class="demo-form flex_tb"
    :rules="state.addRules"
  >
    <el-form-item label="用戶名">
      <el-input
        placeholder="用戶名"
        v-model="state.form.name"
        :disabled="true"
        size="medium"
      ></el-input>
    </el-form-item>
    <el-form-item label="電郵" prop="email">
      <el-input
        placeholder="請輸入電郵"
        v-model="state.form.email"
        size="medium"
      ></el-input>
    </el-form-item>
    <el-form-item label="電話" prop="phone">
      <el-input
        placeholder="請輸入電話"
        v-model="state.form.phone"
        size="medium"
      ></el-input>
    </el-form-item>
    <el-form-item label=" ">
      <el-button type="primary" @click="seach" size="medium">確定</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";

export default {
  setup(props, { emit }) {
    const validateEmail = (rule, value, callback) => {
      // 郵箱正則校驗
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          value
        )
      ) {
        callback(new Error("請輸入正確的電郵"));
      } else {
        callback();
      }
    };

    const validatePhone = (rule, value, callback) => {
      // 手機號碼正則校驗
      if (
        !(
          /^([5|6|8|9|2|3])\d{7}$/.test(value) ||
          /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/.test(
            value
          )
        )
      ) {
        callback(new Error("請輸入正確的號碼"));
      } else {
        callback();
      }
    };

    const state = reactive({
      formInline: "",
      form: {
        name: "",
        phone: "",
        email: "",
      },
      addRules: {
        email: [
          { required: true, message: "請輸入電郵", trigger: "blur" },
          {
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "請輸入電話", trigger: "blur" },
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
      },
    });

    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $utils,$message } = proxy;

    const reset = () => {
      $http
        .getAdminInfo({
          TOKEN: $utils.getCookie("token"),
        })
        .then((res) => {
          state.form.name = res.data.username;
          state.form.phone = res.data.phone;
          state.form.email = res.data.email;
        });
    };
    const resetValue = () => {
      // 重置
      state.form = {
        seatch_name: "",
        region: "",
      };
    };

    reset();

    const seach = () => {
      state.formInline.validate(async (valid) => {
        if (!valid) return;
        const res = await $http.updInfo({
          email: state.form.email,
          phone: state.form.phone,
        });
        if (res.status !== 200) return;
        $message.success("修改成功");
        reset();
      });
    };

    return {
      state,
      resetValue,
      seach,
    };
  },
};
</script>
